import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'

import axios from 'axios'
const myApi = axios.create({
  withCredentials: true,
})

import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

store.state.myApi = myApi

const app = createApp(App)
app.use(router)

app.component('Datepicker', Datepicker)
app.use(store)
app.mount('#app')
