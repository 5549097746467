import { h, resolveComponent } from 'vue'
import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/deliverer',
    children: [
      {
        path: '/deliverer',
        name: 'Delivery',
        component: () => import('@/views/Delivery.vue'),
        children: [
          {
            path: '/deliverer/:user',
            name: 'DeliveryByUN',
            props: true,
            component: () => import('@/views/Delivery.vue'),
            children: [],
          },
        ],
      },
      {
        path: '/package',
        name: 'Package',
        component: () => import('@/views/Package.vue'),
        children: [
          {
            path: '/package/:user',
            name: 'PackageByUN',
            props: true,
            component: () => import('@/views/Package.vue'),
            children: [],
          },
        ],
      },
    ],
  },
]

const router = createRouter({
  //history: createWebHashHistory('https://92.255.79.59/all'),
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
